import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { IVariantProductCollection } from "@/models/grant/IVariantProductCollection";
export async function fetchVariantProductCollection(
    itemsPerPage: number,
    page: number,
    uuid?: string | null,
    criteria?: string | undefined,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    },
    name?: string
) {
    const loading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const variantProductCollection = Array<IVariantProductCollection>();
    const tableData = ref<Array<IVariantProductCollection>>(variantProductCollection);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: name,
    };

    if (criteria !== "") {
        params = { ...params, name: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
        };
    }

    try {
        const { data } = await ApiService.get(
            "/admin/variant_product_collection_for_grant",
            {
                params: params,
                headers: { Accept: "application/ld+json" },
            }
        );

        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index) => {
            item.id = index;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    loading.value = false;
    return [loading, totalRecords, tableData];
}

export async function createVariantProductCollection(data: object) {
  try {
    await ApiService.post("admin/variant_product_collection", data);
  } catch (error) {
    swal.error();
  }
}

export async function updateVariantProductCollection(uuid : string, data: object) {
  try {
    await ApiService.patch("admin/variant_product_collection", uuid, data);
  } catch (error) {
    swal.error();
  }
}

export async function getVariantProductCollection(uuid: string) {
  try {
    return await ApiService.get("admin/variant_product_collections/" + uuid);
  } catch (error) {
    swal.error();
  }
}