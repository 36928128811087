import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { PropertyValue, IAddVariantProductsToPropertyValue } from "@/models/product/property/PropertyValue";

export async function fetchPropertyValuesByProperty(
    itemsPerPage: number,
    page: number,
    searchByValue: string,
    propertyUuid?: string | string[] | null
):Promise<[boolean, number, PropertyValue[]]> {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const propertyValues = Array<PropertyValue>();
    const tableData = ref<Array<PropertyValue>>(propertyValues);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: searchByValue,
    }

    try {
        const { data } = await ApiService.get(`admin/property_values/${propertyUuid}`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item: PropertyValue) => {
          tableData.value.push(item);
        });
    } catch (error) {
        swal.error('An error occurred while fetching property Values.');
    }

    isLoading.value = false;
    return [isLoading.value, totalRecords.value, tableData.value];
}

export async function addVariantProductsToPropertyValue(params: IAddVariantProductsToPropertyValue) {
    try {
        await ApiService.patchWithoutSlug("admin/variant_product_property_value", params);
    } catch (error) {
        swal.error();
    }
}
