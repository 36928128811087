

import { defineComponent } from 'vue'
import { useAuthStore } from '@/stores/auth/authStore'
import { accountService } from '@/_services'

export default  defineComponent({
  name : "LoginPage",
  data(){
    return{
      user :{
        username :'',
        password:''
      }, 
      authStore: useAuthStore(),
      isConnecting: false,
    }
  },
  methods:{
    login(){
      this.isConnecting = true;
      this.authStore.login(this.user)
   
      .then(data => {
        this.$router.push('/admin/dashboard')
      }).catch(error => console.log(error))
      
    }    
  }
})
