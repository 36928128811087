import { IJackpot } from "@/models/product/jackpot/IJackpot";
import ApiService from "../api.service";
import { ref } from 'vue';
import swal from "@/_helpers/swal";

export async function fetchJackpot(itemsPerPage , page = {}) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const jackpot = Array<IJackpot>();
    const tableData = ref<Array<IJackpot>>(jackpot);
  
    try {
      const { data } = await ApiService.get('admin/variant_product_reductions', {
        headers: { Accept: 'application/ld+json' },
      });
      totalRecords.value = data.data['hydra:totalItems'];
      data.data['hydra:member'].forEach((item: IJackpot) => {
        tableData.value.push(item);
      });
    
    } catch (error) {
      alert(error)
      swal.error('Une erreur s\'est produite lors de la récupération de jackpot.');
    }
  
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}