import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import {Document} from "@/models/product/document/Document";
export async function fetchDocumentsAvailable(itemsPerPage, page, paramsFilter) {
  const isLoading = ref<boolean>(true);
  const totalRecords = ref<number>(0);
  const documents = Array<Document>();
    const tableData = ref<Array<Document>>(documents);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: paramsFilter.name,
        supplier: paramsFilter.supplier,
        productName: paramsFilter.productName
    };

    try {
        const {data} = await ApiService.get('product_documents?availability=1', {
            params: params,
            headers: {Accept: 'application/ld+json'},
        });
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Document) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching properties.');
  }

  isLoading.value = false;
  return [isLoading, totalRecords,  tableData];
}
export async function fetchDocumentsNotAvailable(itemsPerPage, page, paramsFilter) {
  const isLoading = ref<boolean>(true);
  const totalRecords = ref<number>(0);
  const documents = Array<Document>();
  const tableData = ref<Array<Document>>(documents);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: paramsFilter.name,
        supplier: paramsFilter.supplier,
        productName: paramsFilter.productName,
        orderNumber: paramsFilter.orderNumber
    };

  try {
    const { data } = await ApiService.get('product_documents?availability=0', {
        params: params,
        headers: { Accept: 'application/ld+json' },
    });
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Document) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching properties.');
  }

  isLoading.value = false;
  return [isLoading,totalRecords, tableData];
}

export async function fetchDocumentsStockDocuments(isAvailable, itemsPerPage, page, searchByName) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const documents = Array<Document>();
    const tableData = ref<Array<Document>>(documents);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: searchByName,
        isAvailable: isAvailable
    };
  try {
    const { data } = await ApiService.get('variant_product_documents', {
        params:params,
      headers: { Accept: 'application/ld+json' },
    });
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Document) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching properties.');
  }

  isLoading.value = false;
  return [isLoading,totalRecords, tableData];
}

export async function deleteDocument(uuid: string) {
  try {
    await ApiService.delete(`product_documents/${uuid}`);
  } catch (error) {
    swal.error();
  }
}

export async function downloadSoldDocumentCsv(startDate: string, endDate: string) {
    try {
        const { data } = await ApiService.get(`admin/export/sold_document/${startDate}/${endDate}`,
            { responseType: 'blob' }
        );
        
        return data;
    } catch (error) {
        swal.error();
    }
}
