import { createApp } from "vue";
import { createPinia } from 'pinia'
import DashboardLayout from "./components/DashboardLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";
import VueFeather from 'vue-feather';
import "./assets/tailwind.css";

import { createI18n } from "vue-i18n";

// import translations
import fr from "./locales/fr.json";
import en from "./locales/en.json";


// configure i18n
const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    messages: { fr, en },
    legacy: false
  });

const pinia = createPinia()

import App from "./App.vue";
import router from "./router";
import { defaultConfig, plugin } from "@formkit/vue";
import config from "../formkit.config";
import ApiService from "@/_services/api.service";
import PrimeVue from "primevue/config";
import InlineSvg from "vue-inline-svg";

// create and start the app
const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(plugin, defaultConfig(config));
app.use(PrimeVue);

ApiService.init(app);

app.mount("#app");


app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);
app.component(VueFeather.name, VueFeather);
app.component('inline-svg', InlineSvg);



