import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { Code } from "@/models/product/code/Code";
export async function fetchCodesAvailable(itemsPerPage, page, paramsFilter) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const codes = Array<Code>();
    const tableData = ref<Array<Code>>(codes);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: paramsFilter.name,
        supplier: paramsFilter.supplier,
        productName: paramsFilter.productName    
    };

    try {
        const {data} = await ApiService.get('product_codes?availability=1', {
            headers: {Accept: 'application/ld+json'},
            params: params
        });
    
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Code) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching codes.');
  }

  isLoading.value = false;
  return [isLoading, totalRecords, tableData];
}

export async function fetchCodesNotAvailable(itemsPerPage, page, paramsFilter) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const codes = Array<Code>();
    const tableData = ref<Array<Code>>(codes);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: paramsFilter.name,
        supplier: paramsFilter.supplier,
        productName: paramsFilter.productName,
        orderNumber: paramsFilter.orderNumber
    };
    try {
        const {data} = await ApiService.get('product_codes?availability=0', {
            headers: {Accept: 'application/ld+json'},
            params: params
        });
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Code) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching codes.');
  }

  isLoading.value = false;
  return [isLoading, totalRecords, tableData];
}

export async function fetchCodesStockSocuments(itemsPerPage, page, searchByProductName) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const codes = Array<Code>();
    const tableData = ref<Array<Code>>(codes);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        name: searchByProductName
    };
    try {
        const {data} = await ApiService.get('variant_product_codes', {
            headers: {Accept: 'application/ld+json'},
            params: params
        });
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: Code) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching codes.');
  }

  isLoading.value = false;
  return [isLoading, totalRecords, tableData];
}
